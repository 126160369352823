:root {
  --doc-height: 100%;
}

* {
  box-sizing: border-box;
}

html, body {
  overscroll-behavior-x: none;
}

body {
  font-family: var(--pri-ft-fm);
  font-size: var(--pri-ft-sz);
  font-weight: 400;
  line-height: 1;
  color: var(--pri-clr);
  margin: 0;
}

body.is-fixed {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body.is-immuned {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body.is-credential ._refLayoutContainer  {
  min-height: calc(100vh - 120px);
  margin-top: 120px;
}

body.is-credential ._refLayoutContainer > section {
  height: calc(100vh - 120px);
}

b {
  font-weight: 400;
}

b, 
strong {
  font-weight: 600 !important;
}

a {
  position: relative;
  cursor: pointer;
  color: var(--pri-clr-lnk__hvr);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

button {
  font-family: var(--pri-ft-fm);
  font-size: var(--pri-ft-sz);
  font-weight: 400;
  line-height: 1;
  color: var(--pri-clr);
  display: inline-grid;
  height: 30px;
  cursor: pointer;
  border: 0;
  border-radius: var(--sec-rd);
  outline: none;
  position: relative;
  align-items: center;
  padding: 0 16px;
  user-select: none;
  text-transform: capitalize;
  transform-origin: 100% 0%;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  &:disabled {
    pointer-events: none;
    opacity: .7;
    cursor: default;
  }
}
  
label {
  font-weight: 400;
}

input, 
textarea {
  font-family: var(--pri-ft-fm);
  font-size: var(--pri-ft-sz);
  font-weight: 400;
  position: relative;
  border: 1px solid var(--ck-clr-ln);
  border-radius: var(--ter-rd);
  background-color: var(--cl-clr-bg);
  color: var(--inp-clr);
  width: 100%;
  &:not(:disabled):not([type='submit']) {
    &:focus, &:active, &:active:focus {
      border: 1px solid var(--pri-clr-ln__fc);
      box-shadow: none;
      outline: none;
    }
  } 
  &:disabled {
    background-color: var(--mt-clr-bg__fc);
    color: var(--mt-clr);
  }
  &::placeholder {
    font-style: italic;
  }
}

textarea[name='input-message'] {
  &:focus, 
  &:active, 
  &:active:focus {
    box-shadow: none !important;
    border: 0 !important;
    outline: none !important;
  }
}

input {
  height: 30px;
  padding: 8px 12px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

textarea {
  padding: 1em;
  min-height: 100px;
  resize: none;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.3;
  letter-spacing: 0.3px;
}

h1, 
h2, 
h3, 
h4 {
  font-weight: 600;
  margin: 0;
}

h1 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: 0.4px;
  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: 0.3px;
  }
}

h2 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.4px;
  @media (min-width: 425px) {
    font-size: 24px;
    line-height: 36px;
  }
}

h3 {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: normal;
  @media (min-width: 425px) {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.4px;
  }
}

p {
  margin: 0 0 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type=number] {
  -moz-appearance: textfield !important;
}

input[type="checkbox"] {
  height: 14px;
  width: 14px;
  background-color: var(--pri-clr-bg);
  border: 1px solid var(--ck-clr-ln);
  border-radius: 3px;
}

[draggable="true"] {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 1;
}

[draggable="true"] * {
  cursor: move;
}