#popup {
  width: 0;
  height: 0;
}

.bold-fw { 
  font-weight: 600 !important; 
}

.extra-fw { 
  font-weight: 700 !important; 
}

.mobile-fw { 
  display: block !important; 
}

.hidden {
  visibility: hidden;
  font-size: 0;
  width: 0 !important;
  height: 0 !important;
  min-height: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.none-trans { 
  text-transform: none !important; 
}

.tags__input input {
  height: auto !important;
  padding: 4px 0 2px !important;
}

._refWithNotif.fullWidthLeft #notif_action {
  padding: 0 1em 0 1.5em;
}

._refWithNotif.fullWidthLeft #notif_action { 
  padding: 0 1em 0 1.5em; 
}

._refWithNotif.fullWidthLeft #notif_action section {
  width: auto;
  margin-left: 0;
  margin-right: 0;
}

.clear-li {
  list-style: none;
  padding: 0;
  margin: 0;
}

._refCollapse {
  position: relative;
}

._refCollapseButton {
  padding-right: 12px !important;
  text-align: left;
  width: 78px !important;
  text-transform: none;
  font-size: 13px;
}

._refCollapseButton::after {
  position: absolute;
  content: '';
  right: 0;
  padding: 2px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-bottom: 2px;
}

._refCollapseButton.is-active::after {
  transform: rotate(-135deg);
  margin-top: 6px;
}

._refHidden ._refLoading {  
  display: none; 
}

.ck-editor.isFocused {
	border: 1px solid var(--pri-clr-ln__fc) !important; 
	border-radius: 6px;
	box-shadow: none;
	outline: none;
	.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable), .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
		border: none;
	}
}

._ref_CKEditorContainer {
  display: flex;
  position: relative;
}

._ref_CKEditorContainer .ck.ck-editor {
  width: 100%;
  max-width: calc(100vw - 30px);
}

.multi-elipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: pre-wrap !important;
  overflow-wrap: anywhere !important;
  word-break: break-word !important;
  line-height: 16px;
}

.third-elipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: pre-wrap !important;
  overflow-wrap: anywhere !important;
  word-break: break-word !important;
  line-height: 16px;
}

._flyByDFor, ._hipByDFor {
  padding-left: 0.625em !important;
  padding-right: 0.625em !important;
}

._refSortButton { 
  cursor: pointer; 
  width: 100%;
}

._refSortButton i {
  display: inline-block;
  vertical-align: text-top;
  margin-left: 2px;
}

._refSortButton dt {
  display: inline;
  width: auto;
  /* letter-spacing: -.09px; */
}

._refSortButton.left:after {
  content: '';
  display: none;
}

._refSortButton:after, ._refSortButton.left:before {
  display: inline-block;
  pointer-events: none;
  position: relative;
  content: url('../images/icons/arrow-double.svg');
  height: 12px;
  width: 12px;
  left: -1px;
  top: 2px;
  transform: rotate(-90deg);
}

._refSortButton.ascending:after, ._refSortButton.descending:after, ._refSortButton.left.ascending:before, ._refSortButton.left.descending:before {
  content: url('../images/icons/arrow-up.svg');
  transform: rotate(0deg);
}

._refSortButton.descending:after, ._refSortButton.descending.left:before { 
  transform: rotate(-180deg); 
}

.loadingWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.loadingWrapper div {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(50%,-50%);
}

.loadingOverlay {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.none {
  display: none !important;
}

.freezeColumn {
  position: sticky;
  z-index: 8;
  background: white;
  align-self: stretch;
}
.is-highlighted {
  background-color: rgba(232, 255, 237, .7);
}

.is-highlighted {
  background-color: rgba(232, 255, 237, .7);
}

@media only screen and (min-width: 375px) {
  ._flyByDFor {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  ._hipByDFor {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media only screen and (max-width: 767.9px) {
  ._refScrollContainer { 
    padding-bottom: 69px; 
  }
}
@media only screen and (min-width: 768px) {
  ._ref_CKEditorContainer .ck.ck-editor {
    max-width: calc(100vw - 80px);
    width: 100%;
  }
  .mobile-fw { 
    display: none  !important; 
  }
}
@media only screen and (min-width: 1024px) {
  ._ref_CKEditorContainer .ck.ck-editor { 
    max-width: 760px; 
  }
}
@media only screen and (min-width: 1366px) {
  ._flyByDFor {
    padding-left: 28px !important;
    padding-right: 20px !important;
  }
  ._hipByDFor {
    padding-left: 28px !important;
    padding-right: 20px !important;
  }
}