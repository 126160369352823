
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-Thin.ttf') format('opentype');
  font-weight: 100;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-ThinItalic.ttf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-Light.ttf') format('opentype');
  font-weight: 300;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-LightItalic.ttf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-Regular.ttf') format('opentype');
  font-weight: 400;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-Italic.ttf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-Bold.ttf') format('opentype');
  font-weight: 600;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-BoldItalic.ttf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-Black.ttf') format('opentype');
  font-weight: 900;
}

@font-face{
  font-family: 'Lato';
  src: url('./../fonts/Lato-BlackItalic.ttf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face{
  font-family: 'Proxima Nova';
  src:  local('Proxima Nova Light'), local('ProximaNova-Light'),
        url('./../fonts/subset-ProximaNova-Light.woff2') format('woff2'), 
        url('./../fonts/subset-ProximaNova-Light.woff') format('woff'),
        url('./../fonts/subset-ProximaNova-Light.ttf') format('truetype'),
        url('./../fonts/subset-ProximaNova-Light.eot') format('embedded-opentype');
  font-weight: 100;
}

@font-face{
  font-family: 'Proxima Nova';
  src:  local('Proxima Nova Regular'), local('ProximaNova-Regular'),
        url('./../fonts/subset-ProximaNova-Regular.woff2') format('woff2'), 
        url('./../fonts/subset-ProximaNova-Regular.woff') format('woff'),
        url('./../fonts/subset-ProximaNova-Regular.ttf') format('truetype'),
        url('./../fonts/subset-ProximaNova-Regular.eot') format('embedded-opentype');
  font-weight: 400;
}

@font-face{
  font-family: 'Proxima Nova';
  src:  local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
        url('./../fonts/subset-ProximaNova-RegularIt.woff2') format('woff2'), 
        url('./../fonts/subset-ProximaNova-RegularIt.woff') format('woff'),
        url('./../fonts/subset-ProximaNova-RegularIt.ttf') format('truetype'),
        url('./../fonts/subset-ProximaNova-RegularIt.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face{
  font-family: 'Proxima Nova';
  src:  local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
        url('./../fonts/subset-ProximaNova-Semibold.woff2') format('woff2'), 
        url('./../fonts/subset-ProximaNova-Semibold.woff') format('woff'),
        url('./../fonts/subset-ProximaNova-Semibold.ttf') format('truetype'),
        url('./../fonts/subset-ProximaNova-Semibold.eot') format('embedded-opentype');
  font-weight: 600;
}

@font-face{
  font-family: 'Proxima Nova';
  src:  local('Proxima Nova Bold'), local('ProximaNova-Bold'),
        url('./../fonts/subset-ProximaNova-Bold.woff2') format('woff2'), 
        url('./../fonts/subset-ProximaNova-Bold.woff') format('woff'),
        url('./../fonts/subset-ProximaNova-Bold.ttf') format('truetype'),
        url('./../fonts/subset-ProximaNova-Bold.eot') format('embedded-opentype');
  font-weight: 700;
}

@font-face{
  font-family: 'Proxima Nova';
  src: url('./../fonts/ProximaNova-Black.otf') format('opentype');
  font-weight: 900;
}
